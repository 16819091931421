var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function () {
            this$1.api.isSuccesful = false
            this$1.fetch()
          },
        },
      }),
      !_vm.api.isLoading
        ? _c("v-data-table", {
            attrs: {
              items: _vm.filteredData,
              search: _vm.search,
              headers: _vm.dataHeader,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                    ]
                  },
                },
                {
                  key: "item.job_schedulers_status",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.job_schedulers_status === "subscribed"
                        ? _c(
                            "v-chip",
                            { attrs: { small: "", color: "success" } },
                            [
                              _vm._v(
                                " " + _vm._s(item.job_schedulers_status) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      item.job_schedulers_status === "pending"
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                small: "",
                                color: "warning",
                                outlined: "",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.job_schedulers_status) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.job_schedulers_data",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("ul", [
                        _c("li", [_vm._v(" " + _vm._s(item.plan_name) + " ")]),
                        item.stripe_coupon != null
                          ? _c("li", {
                              domProps: {
                                innerHTML: _vm._s(item.stripe_coupon),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.created_by_username",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("ACopyAbleText", {
                        attrs: { text: item.created_by_username },
                      }),
                    ]
                  },
                },
                {
                  key: "item.company_name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "PageCompaniesDetail",
                              params: { id: item.company_id },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.company_name) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.job_schedulers_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("ACopyAbleText", {
                        attrs: { text: item.job_schedulers_date },
                      }),
                    ]
                  },
                },
                {
                  key: "item.job_schedulers_created_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("ACopyAbleText", {
                        attrs: { text: item.job_schedulers_created_at },
                      }),
                    ]
                  },
                },
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "text-h4 pt-4",
                          attrs: { flat: "", height: "auto" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 my-3",
                                        },
                                        [_vm._v(" Scheduled Subscriptions ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("AFilterWithoutRefresh", {
                                    attrs: {
                                      data: _vm.data,
                                      filterHeaders: _vm.dataHeader,
                                    },
                                    on: {
                                      reset: function (data) {
                                        _vm.filteredData = data
                                      },
                                      filter: function (data) {
                                        _vm.filteredData = data
                                      },
                                      refresh: _vm.fetch,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.action",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" Actions ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.actions, function (data, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.executeActions(data, item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(data.text) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3164221365
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }